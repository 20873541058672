#contact-form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    /* Ensure it is on top of other content */
    background-color: #cff4fd;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    /* Ensure it is behind the form but above other content */
}

.contact-form label {
    margin-bottom: 10px;
}

.contact-form input,
.contact-form textarea {
    width: calc(100% - 10px);
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-right: 0px;
}

.contact-form button {
    padding: 10px 15px;
    background-color: #0077b5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #005f8b;
}

.close-button {
    position: absolute;
    /* Position it absolutely within the form container */
    top: 10px;
    /* Adjust as needed */
    right: 10px;
    /* Adjust as needed */
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}