.portfolio-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 200px;
    height: 100vh;
    background-color: #cff4fd;
    /* border-left: 1px solid #ddd; */
    padding: 20px;
    /* box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); */
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    transition: transform 0.3s ease;
    transform: translateX(100%);
}

.portfolio-sidebar.open {
    transform: translateX(0);
}

.portfolio-sidebar h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.portfolio-sidebar a {
    margin-top: 20px;
    text-decoration: none;
    color: inherit;
}

.portfolio-sidebar i {
    font-size: 1.5rem;
}

.app-content {
    transition: margin-right 0.3s ease;
}

.app-content.shifted {
    margin-right: 250px;
}